import React from 'react';
import { Loader2, AlertCircle } from 'lucide-react';
import { CampaignStatus, Result } from '../types/campaign';

interface ResultsTableProps {
  campaignId: string;
  status: CampaignStatus;
  results: Result[];
}

const CHANGE_NAMES:any = {
  LojasAtivas: "Lojas Ativas",
  LojasNovas: "Lojas Novas"
}

function formatNumber(value:any) {
  if (value == 'null') return '';
  if (!value) return '-';
  let [integerPart, decimalPart] = value.toString().split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2);
      return `${integerPart},${decimalPart}`;
  } else {
      return integerPart;
  }
}

const ResultsTable: React.FC<ResultsTableProps> = ({ campaignId, status, results }) => {
  if (status === 'PROCESSING') {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <Loader2 className="h-12 w-12 text-blue-600 animate-spin mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Processando Resultados
        </h3>
        <p className="text-sm text-gray-500 text-center max-w-sm">
          Os resultados da simulação estão sendo calculados. Este processo pode levar alguns minutos.
        </p>
      </div>
    );
  }

  if (status === 'ERROR') {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <div className="bg-red-50 rounded-full p-3 mb-4">
          <AlertCircle className="h-8 w-8 text-red-600" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Erro no Processamento
        </h3>
        <p className="text-sm text-gray-500 text-center max-w-sm">
          Ocorreu um erro durante o processamento da simulação. Por favor, tente novamente mais tarde.
        </p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Métrica
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Projeção
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Margem de Erro
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Intervalo de Confiança
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {results.map((result, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {CHANGE_NAMES[result.metric] ? CHANGE_NAMES[result.metric] : result.metric}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatNumber(result.projection)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatNumber(result.marginOfError)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {result.confidenceInterval && result.confidenceInterval.split(' - ').length ? formatNumber((result.confidenceInterval.split(' - ')[0])) + ' - ' + formatNumber((result.confidenceInterval.split(' - ')[1])) : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ResultsTable;