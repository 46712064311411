import React, { useState, useEffect } from 'react';
import { Eye, Plus, Search, Filter } from 'lucide-react';
import StatusBadge from './StatusBadge';
import StatusFilter from './StatusFilter';
import CampaignModal from './CampaignModal';
import CampaignFormModal from './CampaignFormModal';
import { Campaign, CampaignStatus } from '../types/campaign';
import { PageTitle } from '../../../../_metronic/layout/core';
import EmptyState from './EmptyState';
import * as API from '../../../../app/api';

const CampaignHistory: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<CampaignStatus | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (campaigns.some(c => c.status === 'PROCESSING')) {
      interval = setInterval(() => {
        getCampaigns();
      }, 30000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [campaigns]);

  const getCampaigns = async () => {
    const response = await API.getCampaigns();
    response.data.forEach((campaign: any) => {
      try {
        campaign.data = JSON.parse(campaign.data);
        if (campaign.result) campaign.results = JSON.parse(campaign.result);
      } catch (e) {}
    });
    setCampaigns(response.data);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredCampaigns = campaigns.filter(campaign => {
    const matchesSearch =
      campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campaign.run_id.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = selectedStatus ? campaign.status === selectedStatus : true;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <PageTitle>Histórico de Campanhas</PageTitle>
          {campaigns.length > 0 ? (
            <div className="flex items-center gap-4">
              <button
                onClick={() => setShowNewCampaignModal(true)}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Plus className="w-5 h-5 mr-2" />
                Nova Campanha
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {campaigns.length > 0 ? (
          <>
            <div className="mb-6 flex gap-4">
              <div className="flex-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Buscar por nome ou ID..."
                  className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition-colors duration-200"
                />
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowStatusFilter(!showStatusFilter)}
                  className="inline-flex items-center px-4 py-2.5 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Filter className="h-4 w-4 mr-2" />
                  Filtros
                  {selectedStatus && (
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      1
                    </span>
                  )}
                </button>
                {showStatusFilter && (
                  <StatusFilter
                    selectedStatus={selectedStatus}
                    onStatusChange={setSelectedStatus}
                    onClose={() => setShowStatusFilter(false)}
                  />
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-xl overflow-hidden border border-gray-200">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        ID da Campanha
                      </th>
                      <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nome
                      </th>
                      <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data da Criação
                      </th>
                      <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3.5 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredCampaigns.map((campaign) => (
                      <tr
                        key={campaign.run_id}
                        className="hover:bg-gray-50 transition-colors duration-150"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{campaign.run_id}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{campaign.name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{formatDate(campaign.createdAt)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <StatusBadge status={campaign.status} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => setSelectedCampaign(campaign)}
                            className="inline-flex items-center px-3.5 py-1.5 border border-transparent text-sm font-medium rounded-lg text-blue-600 hover:text-blue-600 hover:bg-blue-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <Eye className="h-4 w-4 mr-1.5" />
                            Visualizar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className="bg-white shadow-lg rounded-xl border border-gray-200">
            <EmptyState onCreateCampaign={() => setShowNewCampaignModal(true)} />
          </div>
        )}
      </div>
      {selectedCampaign && (
        <CampaignModal
          campaign={selectedCampaign}
          onClose={() => setSelectedCampaign(null)}
        />
      )}
      {showNewCampaignModal && (
        <CampaignFormModal
          onClose={() => {
            setShowNewCampaignModal(false);
            getCampaigns();
          }}
        />
      )}
    </div>
  );
};

export default CampaignHistory;
