import React, { useState, useEffect } from 'react';
import { X, Calendar, Tag, Building2, MapPin, Banknote, Package, Percent, Clock } from 'lucide-react';
import { Campaign } from '../types/campaign';
import { formatCurrency } from '../utils/currency';
import ResultsTable from './ResultsTable';
import StatusBadge from './StatusBadge';

const VARIABLES:any = {
  desconto_medio: "averageDiscount",
  duracao_semanas: "duration",
  regiao: "region",
  classe_campanha: "campaignClass",
  investimento: "investment",
  bu: "businessUnit",
  quantidade_produtos: "eanQuantity",
  name: "name"
}

interface CampaignModalProps {
  campaign: Campaign;
  onClose: () => void;
}

const CampaignModal: React.FC<CampaignModalProps> = ({ campaign, onClose }) => {
  const [data, setData] = useState<any>({});

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const replaceVariables = (body:any) => {
    const data:any = {};
    for (const key in VARIABLES) {
        data[VARIABLES[key]] = body[key];
    }
    return data;
  }

  useEffect(() => {
    setData(replaceVariables(campaign.data));
  }, []);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">{campaign.name}</h2>
            <div className="flex items-center gap-2 mt-1">
              <p className="text-sm text-gray-500">ID: {campaign.run_id}</p>
              <StatusBadge status={campaign.status} />
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6 text-gray-500" />
          </button>
        </div>

        <div className="p-6">
          <div className="bg-gray-50 rounded-xl p-6 mb-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Detalhes da Campanha</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="flex items-start space-x-3">
                <Calendar className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Data de Criação</p>
                  <p className="mt-1 text-sm text-gray-900">{formatDate(campaign.createdAt)}</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <Tag className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Classe da Campanha</p>
                  <p className="mt-1 text-sm text-gray-900">{data.campaignClass}</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <Building2 className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Unidade de Negócio</p>
                  <p className="mt-1 text-sm text-gray-900">{data.businessUnit}</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <MapPin className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Região</p>
                  <p className="mt-1 text-sm text-gray-900">{data.region}</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <Banknote className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Investimento</p>
                  <p className="mt-1 text-sm text-gray-900 font-medium">
                    {formatCurrency(data.investment)}
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <Package className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Quantidade de EAN</p>
                  <p className="mt-1 text-sm text-gray-900">{data.eanQuantity}</p>
                </div>
              </div>
              { data.averageDiscount ? 
              <div className="flex items-start space-x-3">
                <Percent className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Desconto Médio</p>
                  <p className="mt-1 text-sm text-gray-900">{data.averageDiscount}%</p>
                </div>
              </div> : null }
              { data.duration ? 
              <div className="flex items-start space-x-3">
                <Clock className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Duração</p>
                  <p className="mt-1 text-sm text-gray-900">{data.duration} semanas</p>
                </div>
              </div> : null }
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Resultados da Simulação</h3>
            <div className="bg-white rounded-xl border border-gray-200">
              <ResultsTable campaignId={campaign.run_id} status={campaign.status} results={campaign.results} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignModal;